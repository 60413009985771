<template>
  <div>
    <br>
    <h5>Problem Description</h5>
    <p class="big mt-2">
      In the MagicGraph, you see a pair of parallel lines (shown in blue). These lines are intersected by line AB (shown in red). Line AB is called a transversal. The angles &alpha; and &beta; are a  pair of alternate interior angles formed by transversal AB.<br>
      The points A and B can moved interactively. No matter the location of points A and B, the two alternate interior angles always remain equal to each other. This property is called equality of alternate interior angles.
    </p>
    <div style="text-align:left">
      <h5> MagicGraph | Equality of Alternate Interior Angles </h5>
    </div>
    <p class="big mt-2">
      This MagicGraph is a visually interactive demo of equality of alternate interior angles. Tap on <i class="redoer ma-1" /> button to interactively move points A and B.
      Tap on <i class="pauser ma-1" /> button to pause points A and B.
      <br>
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
    <br>
    <h5> MagicGraph Navigation Buttons </h5>
    <p class="big mt-2">
      <i class="redoer ma-1" /> &mdash; is the 'Next' button. Tap on this button to move over to the next question. <br>
      <i class="tester ma-1" /> &mdash; is the 'Check Your Answer' button. Tap on this button to check your answer.<br>
      <i class="logor ma-1" />&mdash;  is the 'Get a Hint' button. Tap on this button to receive a hint on how to solve this problem. <br>
      <i class="undoer ma-1" /> &mdash; is the 'Start Over' button. Tap on this button to start over the exercise from the begining.
    </p>
    <h5> Markings & Indications </h5>
    <p class="big mt-2">
      The marks and indications are located on the top-left corner of the MagicGraph, and show your attempt status. <br>
      <i class="righter ma-1" /> &mdash; the right checkmark indicates that your attempted response was correct. <br>
      <i class="wrong ma-1" /> &mdash; this crossmark indicates that your attempted response was incorrect. <br>
      <i class="missed ma-1" /> &mdash; this exclaimation mark indicates an unattempted (missed) response. <br>
    </p>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'ParallelLines',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Parallel Lines';
    this.$store.commit('navigation/changeTitle', title);
  /* this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Probability', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Probability of an Event', img:'/assets/number-2.svg', action: () => this.goto('types')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
*/
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Parallel Lines',
          titleTemplate: '%s | Learn interactively',
          meta: [
                  {vmid: 'description', name: 'description', content: 'Learn interactively about parallel lines'}
                ]
        }
   },
}
</script>
