import {
  makeResponsive,
  placeTitle,
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  placeGravity,
  placeText,
  placeMiddleText,
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeCircle,
  placeAngle,
  placeDash,
  placeLabel,
  placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
  placeArc,
  placeLogo,
  placeErase,
  placeShuffle,
  placeTest,
  placeSeg,
  setConfig,
  placeStat,
  drawTriangle,
  shuffle,
  placeEraseLeft,
  placeTapeHori,
  placeTapeVert,
  placeSq,
  placeBoldText,
  placeRedo,
  placeUndo,
  drawPoint,
  drawTri,
  setSize,
  labelIt,
  drawDash,
  placeEggs,
  drawLine,
  setWidth,
  drawIntersection,
  drawPerpP,
  drawAngle,
  createSpace,
  createAxes
} from '../Utils';
const Boxes = {
    //JXG.Options.line.highlight=false;
    box1: function () {
    //Create the Board
    var graph =createSpace(-8,8,-7,9);
    graph.suspendUpdate();
    //createAxes(graph);
    // Layering
    graph.options.axis.strokeWidth=0;
    graph.options.layer['image']=17;
    graph.options.layer['text']=19;
    graph.options.layer['line']=16;
    graph.options.layer['point']=17;
    graph.options.layer['glider']=17;
    graph.options.layer['angle']=18;
    graph.options.board.minimizeReflow = 'none';
    graph.options.point.showInfobox =false;
    graph.options.line.highlight=false;
    graph.options.image.highlight=false;
    graph.options.text.highlight=false;
    graph.options.polygon.highlight=false;
//    graph.options.text.display='internal';
    //Make the board responsive
    makeResponsive(graph);
    // Creat titleStyle
    placeLogo(graph);
    placeTitle(graph, 'Pair of Angles', '(In Parallel Lines)');
////////////////////////////////////////////////////////////////////////////////
    var analytics = placeUndo(graph, 'left');
    hoverMe(graph, analytics, 'Previous Step', 0, -5);
    ////////////////////////////////////////////////////
    var erase = placeRedo(graph);
    hoverMe(graph, erase, 'Next Step', 0, -5);
/////////////////////////////////////////////////////////////////////////////
    var i = 0;
    var eggs = placeEggs(graph);
    eggs[0].setAttribute({visible:()=>i>=0});
    ////////////////
    eggs[1].setAttribute({visible:()=>i>=1});
    ////////////////
    eggs[2].setAttribute({visible:()=>i>=2});
    ///////////////////////////All actions go here////////////////////////////////
    var Jt1 = drawPoint(graph, 4, 4);
    setSize(graph, Jt1, 0);
    var St1 = drawPoint(graph, 0.0, 4.0);
    setSize(graph, St1, 0);
    var St3 = drawPoint(graph, -10.0, 4.0);
    St3.setAttribute({visible:false});
    var Pt1 = drawPoint(graph, 0, 4.0);
    Pt1.setAttribute({color:'black'});
    setSize(graph, Pt1, 3);
    labelIt(graph, Pt1, 'A');
    Pt1.label.setAttribute({offset:[0, 20]});
    //Pt1.setAttribute({snapToGrid:true});
    var Pt2 = drawPoint(graph, 4, 0.0);
    Pt2.setAttribute({color:'black'});
    setSize(graph, Pt2, 3);
    labelIt(graph, Pt2, 'B');
    Pt2.label.setAttribute({offset:[0, -20]});
    var Jt2 = drawPoint(graph, 0, 0);
    Jt2.setAttribute({visible:false});
    var PLn1 = drawLine(graph, St1, Jt1);
    PLn1.setAttribute({strokeColor:'blue'});
    setWidth(graph, PLn1, '4');
    var PLn2 = drawLine(graph, Jt2, Pt2);
    PLn2.setAttribute({strokeColor:'blue'});
    setWidth(graph, PLn2, '4');
    //Pt2.setAttribute({snapToGrid:true});
    var Ln =drawLine(graph, Pt1, Pt2);
    Ln.setAttribute({strokeColor:'red'});
    setWidth(graph, Ln, 4);
    var Ptint = drawIntersection(graph, Ln, PLn1);
    setSize(graph, Ptint, 0);
    //////////////////////////////////////////
    var Pt = drawPoint(graph, .0, 0.);
    setSize(graph, Pt, 0);
    var Pt4 = drawPoint(graph, 10.0, 0.);
    setSize(graph, Pt4, 0);
    var Pt5 = drawPoint(graph, -2, 6.);
    setSize(graph, Pt5, 0);
    var Pt6 = drawPoint(graph, -10.0, 0.);
    setSize(graph, Pt6, 0);
    ///////////////////////////////////////
    var perp = drawPerpP(graph, Ln, Pt);
    //perp.setAttribute({fillColor:'white', strokeColor:'black'});
    setSize(graph, perp, 0);
    //labelIt(graph, perp, 'D');
    var Lp = drawLine(graph, Pt, perp);
    Lp.setAttribute({strokeColor:'red'});
    setWidth(graph, Lp, 0);
    //drawPerp(graph, Ln, Pt)
    //var ints = drawIntersection(graph, Ln, perp);
    //ints.setAttribute({size:0});
    var alpha =drawAngle(graph, Pt4, Pt2, perp);
    //alpha.setAttribute({name:()=>(JXG.Math.Geometry.rad(Pt4, Pt2, perp)*180/Math.PI).toFixed(1), fillColor:'blue', visible:()=>i==0});
    alpha.setAttribute({name:'a', fillColor:'blue', visible:()=>i==0});
    alpha.label.setAttribute({offset:[10, 10]});
    var beta =drawAngle(graph, St3, Ptint, Pt2);
    beta.label.setAttribute({offset:[-10, -10]});
    beta.setAttribute({name:'b', fillColor:'blue', visible:()=>i==0});
  //  beta.setAttribute({name:()=>(JXG.Math.Geometry.rad(St3, Ptint, Pt2)*180/Math.PI).toFixed(1), fillColor:'blue', visible:()=>i==0});
    ////////////////////////////////////////
    var gamma =drawAngle(graph, St3, Pt1, Pt5);
    //gamma.setAttribute({name:()=>'&gamma; ='+(JXG.Math.Geometry.rad(St3, Pt1, Pt5)*180/Math.PI).toFixed(1), fillColor:'red', visible:()=>i==1});
    gamma.setAttribute({radius:1, name:'p', fillColor:'green', visible:()=>i==1});
    gamma.label.setAttribute({offset:[-10, 10]});

    var delta =drawAngle(graph, Pt6, Pt2, Pt1);
    //delta.setAttribute({name:()=>'&delta; ='+(JXG.Math.Geometry.rad(Pt6, Pt2, Pt1)*180/Math.PI).toFixed(1), fillColor:'red', visible:()=>i==1});
    delta.setAttribute({radius:1, name:'q', fillColor:'green', visible:()=>i==1});
    delta.label.setAttribute({offset:[-10, 10]});

    var eta =drawAngle(graph, Pt2, Pt1, Jt1);
  //  eta.setAttribute({name:()=>'&delta; ='+(JXG.Math.Geometry.rad(Jt1, Pt1, Pt2)*180/Math.PI).toFixed(1), fillColor:'red', visible:()=>i==2});
    eta.setAttribute({radius:1, name:'x', fillColor:'red', visible:()=>i==2});
    eta.label.setAttribute({offset:[10, -10]});

    var neta =drawAngle(graph, Pt4, Pt2, perp);
    //alpha.setAttribute({name:()=>(JXG.Math.Geometry.rad(Pt4, Pt2, perp)*180/Math.PI).toFixed(1), fillColor:'blue', visible:()=>i==0});
    neta.setAttribute({name:'y', fillColor:'red', visible:()=>i==2});
    neta.label.setAttribute({offset:[10, 10]});
  //////////////////////////////Button Actions////////////////////////////////////
    analytics.on('down', function(){
      if(i>0)
      {
      i-=1;
      }
      else
      {
        i=0;
      }
    });
    erase.on('down', function(){
      if(i<2)
      {
      i+=1;
      }
      else
      {
        i=0;
      }
    })
    var line1 = placeMiddleText(graph, 0, -3.5, 'a and b are alternate interior angles (a = b)');
    line1.setAttribute({color:'blue', visible:()=>i==0});
    var line2 = placeMiddleText(graph, 0, -3.5, 'x and y are consecutive interior angles (x + y = 180)');
    line2.setAttribute({color:'blue', visible:()=>i==2});
    var line3 = placeMiddleText(graph, 0, -3.5, 'p and q are corresponding angles (p =q)');
    line3.setAttribute({color:'blue', visible:()=>i==1});
///////////////////////////////////////////////////////////////////////
    graph.unsuspendUpdate();
                      },
              }
export default Boxes;
